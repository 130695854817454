import { Button, Modal } from 'react-bootstrap';

function CustomModal({
    title,
    text1,
    warningText,
    secondaryButtonLabel,
    primaryButtonLabel,
    onSecondaryButtonClick,
    onPrimaryButtonClick,
    isLoading,
    icon
}) {
    return (
        <Modal
            show
            onHide={onSecondaryButtonClick}
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {icon ?? <span><i className="bi bi-exclamation-octagon-fill me-2 error-placeholder"/></span>}
                    {title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>{text1}</p>
                <p className={icon ? '' : 'error-placeholder'}>{warningText}</p>
            </Modal.Body>
            <Modal.Footer>
                {secondaryButtonLabel &&
                    <Button className="secondary-button" onClick={onSecondaryButtonClick}>
                        {secondaryButtonLabel}
                    </Button>}
                <Button className="primary-button" disabled={isLoading} onClick={onPrimaryButtonClick}>
                    {isLoading && <div className="spinner-border spinner-border-sm me-1" role="status"/>}
                    {primaryButtonLabel}
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default CustomModal;
