import { useEffect, useState } from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import UsersEmail from './usersEmail';
import { REGEX } from '../../../constants';
import { axios } from '../../../helpers/apiHelper';
import { showToastErrorMessage, showToastSuccessMessage } from '../../../features/toastSlice';

function InviteUsers({ closeModal, setInvitationSent = () => {}, teamToInviteTo }) {
    const [emails, setEmails] = useState([]);
    const [emailsFromCSV, setEmailsFromCSV] = useState([]);
    const [emailsFromCSVAreAllValid, setEmailsFromCSVAreAllValid] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const { userData } = useSelector(state => state.agenda);
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const userTeams = userData?.teams.reduce((acc, team) => ({
        ...acc,
        [team._id]: team
    }), {});

    const validationSchema = Yup.object().shape({
        email: Yup.string().matches(REGEX.EMAIL, { message: t('error_message.email_not_valid'), excludeEmptyString: true }),
        team: Yup.string().required(t('error_message.required_field'))
    });
    const {
        handleSubmit,
        register,
        setError,
        getValues,
        formState: { errors, isSubmitSuccessful },
        reset
    } = useForm({ resolver: yupResolver(validationSchema) });

    const removeEmail = (index) => {
        setEmails(emails.filter((email, i) => index !== i));
    };

    const addEmail = () => {
        const inputEmail = getValues('email');
        if (!emails.includes(inputEmail)) {
            setEmails(prevState => [...prevState, inputEmail]);
            reset();
        } else {
            setError(
                'email',
                {
                    type: 'focus',
                    message: t('error_message.administration.email_address_already_entered')
                },
                { shouldFocus: true }
            );
        }
    };

    const isEmailInputEmpty = () => getValues('email').trim() === '';

    useEffect(() => {
        if (isSubmitSuccessful && !getValues('email')) {
            reset();
        }
    }, [isSubmitSuccessful]);

    const inviteUsersToTeam = async () => {
        const teamFromValues = getValues('team');
        if (isEmailInputEmpty() && emails.length === 0) {
            dispatch(showToastErrorMessage(t('error_message.administration.no_emails_entered')));
            return;
        }
        try {
            setIsLoading(true);
            await axios.post('user/inviteUsers', {
                invitationData: {
                    usersEmails: !isEmailInputEmpty() ? [...emails, getValues('email')] : emails,
                    team: teamFromValues,
                    company: userTeams[teamFromValues].company
                }
            });
            setInvitationSent(true);
            setIsLoading(false);
            dispatch(showToastSuccessMessage(t('success_message.administration.invitations_sent')));
            closeModal();
        } catch (error) {
            setIsLoading(false);
            dispatch(showToastErrorMessage(t('error_message.administration.failed_sending_invitation')));
        }
    };

    const csvFileToArray = (string) => {
        setEmailsFromCSVAreAllValid(true);
        setEmailsFromCSV([]);
        const csvHeader = string.slice(0, string.indexOf('\n')).trim().toUpperCase().split(',');
        const csvRows = string.slice(string.indexOf('\n') + 1).split('\n');
        const indexOfEmails = csvHeader.indexOf('EMAIL');
        const arrayOfEmails = csvRows.reduce((acc, row) => {
            const values = row.trim().split(',');
            const email = values[indexOfEmails];
            const isEmailValid = REGEX.EMAIL.test(email);
            if (!isEmailValid && email !== '') {
                setEmailsFromCSVAreAllValid(false);
            }
            return isEmailValid && !acc.includes(email) && !emails.includes(email) ? [
                ...acc,
                email
            ] : acc;
        }, []);

        return arrayOfEmails;
    };

    const readCsvFile = (sentFile) => {
        if (sentFile) {
            const fileReader = new FileReader();
            fileReader.onload = function (event) {
                const csvOutput = event.target.result;
                const emailsFromCSVResult = csvFileToArray(csvOutput);
                setEmailsFromCSV(emailsFromCSVResult);
            };

            fileReader.readAsText(sentFile);
        }
    };
    useEffect(() => {
        if (emailsFromCSVAreAllValid && emailsFromCSV?.length > 0) {
            setEmails(prevState => [...prevState, ...emailsFromCSV]);
            setEmailsFromCSV([]);
            document.getElementById('csvFile').value = '';
        }
    }, [emailsFromCSV]);

    const readUsersEmailsFromFile = (e) => {
        e.preventDefault();
        setEmails(prevState => [...prevState, ...emailsFromCSV]);
        setEmailsFromCSV([]);
        setEmailsFromCSVAreAllValid(true);
        document.getElementById('csvFile').value = '';
    };

    const handleOnFileChange = (e) => {
        readCsvFile(e.target.files[0]);
    };
    const isRoleUserOrTeamAdmin = userData.memberships.find((membership) => {
        const membershipTeam = membership?.role?.name === 'teamAdmin' || membership?.role?.name === 'user';
        return membershipTeam;
    });

    const selectTeam = () => {
        if (isRoleUserOrTeamAdmin) {
            return userData.memberships
                ?.filter((membership) => membership.role?.name === 'teamAdmin')
                .map((membership) => (
                    <option key={membership.team?._id} value={membership.team?._id}>
                        {membership.team?.name}
                    </option>
                ));
        }
        return userData.teams?.map((team) => (
            <option key={team?._id} value={team?._id}>
                {team?.name}
            </option>
        ));
    };

    return (
        <Modal className="inviteUsers" show centered size="md">
            <Modal.Header className="text-center" closeButton>
                <Modal.Title className="w-100">
                    <span>{t('administration.invite_users')}</span>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit(inviteUsersToTeam)} className="container d-flex flex-column">
                    <Form.Group className="mb-3">
                        <Form.Label>{t('administration.upload_csv')}</Form.Label>
                        <Form.Control
                            id="csvFile"
                            type="file"
                            onChange={handleOnFileChange}
                            accept=".csv, text/csv, application/csv, text/comma-separated-values, application/csv"
                        />
                        <Form.Label className="ps-1 small">{t('administration.warning_csv_file_email')}</Form.Label>
                        {!emailsFromCSVAreAllValid && (
                            <Form.Label className="pt-1 error-placeholder">
                                {t(`error_message.administration.${emailsFromCSV?.length > 0 ?
                                    'csv_entries_not_email_ignored' : 'no_emails_in_csv'}`)}
                            </Form.Label>
                        )}
                    </Form.Group>
                    {emailsFromCSV?.length > 0 && !emailsFromCSVAreAllValid && (
                        <Button className="primary-button mb-3" onClick={readUsersEmailsFromFile}>
                            {t('administration.read_csv_emails')}
                        </Button>)}
                    <Form.Label>{t('administration.enter_emails')}</Form.Label>
                    <Form.Group className="p-2 mb-3 rounded" style={{ border: '2px solid lightgray' }}>
                        <div className="d-flex flex-wrap">
                            {
                                emails.map((email, index) => <UsersEmail
                                    key={index}
                                    email={email}
                                    index={index}
                                    removeEmail={removeEmail}
                                />)
                            }
                        </div>
                        <Form.Control
                            type="email"
                            name="email"
                            {...register('email')}
                            placeholder={emails.length > 0 ? t('administration.add_more') : t('administration.email_template')}
                            isInvalid={errors.email?.message}
                            autoComplete="off"
                            className="form-control border-0 border-bottom shadow-none"
                            onKeyDown={async (e) => {
                                if (e.key === 'Enter' || e.code === 'Space' || e.key === ',') {
                                    e.preventDefault();
                                    if (!isEmailInputEmpty()) {
                                        handleSubmit(addEmail)();
                                    }
                                }
                            }}
                        />
                        {errors.email?.message && <Form.Label className="error-placeholder">{errors.email?.message}</Form.Label>}
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label className="mb-0">{t('administration.users_table.team')}</Form.Label>
                        <Form.Select
                            className="form-control mb-3 shadow-none border-color-primary"
                            isInvalid={errors?.team}
                            placeholder={t('administration.teams_table.companyName')}
                            name="team"
                            defaultValue={teamToInviteTo ?
                                userTeams[teamToInviteTo._id]._id :
                                userTeams[userData.lastSelectedTeam._id]._id
                            }
                            {...register('team')}
                        >
                            <option key="000" value="">{t('best_contribution.please_select')}</option>
                            {selectTeam()}
                        </Form.Select>
                        {errors?.team && <Form.Label className="error-placeholder">{errors.team?.message}</Form.Label>}
                    </Form.Group>
                    <div className="d-flex justify-content-between">
                        <Button className="secondary-button" style={{ width: '30%' }} onClick={closeModal}>
                            {t('dashboard_shared.close')}
                        </Button>
                        <Button type="submit" className="primary-button" disabled={isLoading} style={{ width: '65%' }}>
                            {isLoading && <div className="spinner-border spinner-border-sm me-1" role="status"/>}
                            {t('administration.invite_users')}
                        </Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
}

export default InviteUsers;
