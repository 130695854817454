import { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Dropdown } from 'react-bootstrap';

import { axios } from '../../../../helpers/apiHelper';
import {
    editTaskToggle,
    editBestContributionToggle,
    fetchBestContributions,
    fetchUsers
} from '../../../../features/actionsSlice';
import EditTask from './editTask';
import { AREA_OF_IMPROVEMENT_STATUSES, ROLES_PERMISSIONS } from '../../../../constants';
import BestContribution from './bestContribution';
import EditBestContribution from './editBestContribution';
import PermissionWrapper from '../../../permissionWrapper';
import CustomModal from '../../modalCustom';
import { showToastErrorMessage, showToastSuccessMessage } from '../../../../features/toastSlice';
import PrintButton from '../../printButton';

function ActionPlan({ module }) {
    const dispatch = useDispatch();
    const {
        task: taskInEditMode,
        showEditTask,
        showBestContributionForm,
        createAction,
        users,
        moduleBestContributions,
        teamBestContributions
    } = useSelector((state) => state.actions);
    const { _id: teamId } = useSelector((state) => state.agenda?.userData?.lastSelectedTeam);
    const { t } = useTranslation();
    const [filters, setFilters] = useState({});
    const [areTasksAvailableAfterFiltering, setAreTasksAvailableAfterFiltering] = useState({ value: true, finishedFiltering: false });
    const indexOfNotStarted = AREA_OF_IMPROVEMENT_STATUSES.indexOf('not-started');
    const [filteredBestContributions, setFilteredBestContributions] = useState([]);
    const [showAreYouSureYouWantToDeleteBestContributionModal, setShowAreYouSureYouWantToDeleteBestContributionModal] = useState(false);
    const [bestContributionToDelete, setBestContributionToDelete] = useState();
    const componentToPrintRef = useRef(null);

    const filterTask = (task) => {
        if (filters?.byPerson?.active && task?.responsible !== filters.byPerson.userId && task?.supportBy !== filters.byPerson.userId) {
            return false;
        }
        if (filters?.byStatus?.active && !AREA_OF_IMPROVEMENT_STATUSES[task?.status] &&
            indexOfNotStarted.toString() === filters.byStatus.index.toString()) {
            return true;
        }
        if (filters?.byStatus?.active && filters.byStatus.index !== task?.status) {
            return false;
        }

        // if any of tasks passes filtering, at least one task matches filter
        setAreTasksAvailableAfterFiltering(prevState => ({ ...prevState, value: true }));
        return true;
    };

    const filterTasks = (tasks) => tasks.filter(task => filterTask(task));

    const filterBestContributions = (bestContributionsTemp) => bestContributionsTemp?.map(bestContribution => ({
        ...bestContribution,
        tasks: filterTasks(bestContribution?.tasks),
        areasOfImprovement: bestContribution?.areasOfImprovement.map(areaOfImprovement => ({
            ...areaOfImprovement,
            tasks: filterTasks(areaOfImprovement?.tasks)
        }))
    }));

    useEffect(() => {
        dispatch(editTaskToggle({ isOpened: false, task: {}, createAction: false }));
        setFilters({});
        (async () => {
            await dispatch(fetchUsers(teamId));
            await dispatch(fetchBestContributions({ moduleId: module._id }));
        })();
    }, [module]);

    const save = async (taskData) => {
        try {
            await axios.put(
                `task/update/${taskInEditMode?._id}`,
                { taskData }
            );
            await dispatch(fetchBestContributions({ moduleId: module._id }));
        } catch (err) {
            dispatch(showToastErrorMessage(t('error_message.global_message')));
        }
    };

    const filterModuleBestContributions = () => {
        if (Object.keys(filters).length) {
            setAreTasksAvailableAfterFiltering(prevState => ({ ...prevState, value: false, finishedFiltering: false }));
            const bestContributionsAfterFiltering = filterBestContributions(moduleBestContributions);
            setFilteredBestContributions(bestContributionsAfterFiltering);
            setAreTasksAvailableAfterFiltering(prevState => ({ ...prevState, finishedFiltering: true }));
        } else {
            setFilteredBestContributions(moduleBestContributions);
            setAreTasksAvailableAfterFiltering(prevState => ({ ...prevState, value: true, finishedFiltering: true }));
        }
    };

    useEffect(() => {
        if (moduleBestContributions?.length && Object.keys(filters).length) {
            setAreTasksAvailableAfterFiltering(prevState => ({ ...prevState, value: false, finishedFiltering: false }));
            const bestContributionsAfterFiltering = filterBestContributions(moduleBestContributions);
            setFilteredBestContributions(bestContributionsAfterFiltering);
            setAreTasksAvailableAfterFiltering(prevState => ({ ...prevState, finishedFiltering: true }));
        } else {
            setFilteredBestContributions(moduleBestContributions);
            setAreTasksAvailableAfterFiltering(prevState => ({ ...prevState, value: true, finishedFiltering: true }));
        }
    }, [filters]);

    useEffect(() => {
        filterModuleBestContributions();
    }, [moduleBestContributions]);

    useEffect(() => {
        if (teamBestContributions.length) {
            (async () => {
                await dispatch(fetchBestContributions({ moduleId: module._id }));
            })();
        }
    }, [teamBestContributions]);

    useEffect(() => {
        document.getElementById('best-contributions-top')?.scrollTo({ top: 0, behavior: 'smooth' });
    }, [filters]);

    const filterByPerson = (index) => {
        setFilters(prevState => ({ ...prevState, byPerson: { active: true, index, userId: users[index]._id } }));
    };

    const filterByStatus = (index) => {
        setFilters(prevState => ({ ...prevState, byStatus: { active: true, index } }));
    };

    const removeByStatusFilter = (e) => {
        e.stopPropagation();
        setFilters(prevState => ({ ...prevState, byStatus: { active: false } }));
    };

    const removeByPersonFilter = (e) => {
        e.stopPropagation();
        setFilters(prevState => ({ ...prevState, byPerson: { active: false } }));
    };

    const toggleShowAreYouSureYouWantToDeleteBestContributionModal = () => {
        setShowAreYouSureYouWantToDeleteBestContributionModal(prevState => !prevState);
    };

    const areYouSureYouWantToDeleteBestContribution = async (bestContribution) => {
        toggleShowAreYouSureYouWantToDeleteBestContributionModal();
        setBestContributionToDelete(bestContribution);
    };

    const deleteBestContribution = async () => {
        try {
            await axios.delete(`bestContribution/${bestContributionToDelete?._id}`);
            await dispatch(fetchBestContributions({ moduleId: module._id }));
            toggleShowAreYouSureYouWantToDeleteBestContributionModal();
            dispatch(showToastSuccessMessage(t('success_message.best_contribution.successfully_deleted_best_contribution')));
        } catch (err) {
            dispatch(showToastErrorMessage(t('error_message.best_contribution.deleting_best_contribution_failed')));
        }
    };

    return (
        <PermissionWrapper allowed={[ROLES_PERMISSIONS.BEST_CONTRIBUTION_READ]} displayNoAccessMessage>
            <div ref={componentToPrintRef} className="action-plan">
                <div className="title-filters-container d-flex justify-content-between mb-2">
                    <h2>{module?.title}</h2>
                    <div className="filter-container d-flex align-items-center print-hide">
                        <Dropdown className="action-plan-dropdown">
                            <Dropdown.Toggle variant="success" className="action-plan-toggle border-0" id="dropdown-basic">
                                <div className="filter-button cursor">
                                    <i className="bi bi-person me-2 action-plan-icon"/>
                                    {t('action_plan.filter_by_person')}
                                    {filters?.byPerson?.active && (
                                        <>
                                            {`: ${users[filters.byPerson.index].firstName} ${users[filters.byPerson.index].lastName}`}
                                            <i
                                                style={{ fontSize: '16px' }}
                                                className="bi bi-x-circle-fill align-middle close-icon ms-2"
                                                onClick={(e) => removeByPersonFilter(e)}
                                            />
                                        </>
                                    )}
                                </div>
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="action-plan-dropdown-menu">
                                {users?.map((user, i) => user?.isActive && (
                                    <Dropdown.Item
                                        disabled={filters?.byPerson?.active && filters?.byPerson?.index === i}
                                        key={i}
                                        className="action-plan-dropdown-menu-item"
                                        onClick={() => filterByPerson(i)}
                                    >
                                        {`${user.firstName} ${user.lastName}`}
                                    </Dropdown.Item>
                                ))}
                            </Dropdown.Menu>
                        </Dropdown>
                        <Dropdown className="action-plan-dropdown">
                            <Dropdown.Toggle variant="success" className="action-plan-toggle border-0" id="dropdown-basic">
                                <div className="filter-button cursor">
                                    <i className="bi bi-check2-circle me-2 action-plan-icon"/>
                                    {t('action_plan.filter_by_status')}
                                    {filters?.byStatus?.active && (
                                        <>
                                            {`: ${
                                                t(`best_contribution.task_statuses.${AREA_OF_IMPROVEMENT_STATUSES[filters.byStatus.index]}`)
                                            }`}
                                            <i
                                                style={{ fontSize: '16px' }}
                                                className="bi bi-x-circle-fill align-middle close-icon ms-2"
                                                onClick={(e) => removeByStatusFilter(e)}
                                            />
                                        </>
                                    )}
                                </div>
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="action-plan-dropdown-menu">
                                {AREA_OF_IMPROVEMENT_STATUSES.map((status, i) => (
                                    <Dropdown.Item
                                        disabled={filters?.byStatus?.active && filters?.byStatus?.index === i}
                                        key={i}
                                        className="action-plan-dropdown-menu-item"
                                        onClick={() => filterByStatus(i)}
                                    >
                                        {t(`best_contribution.task_statuses.${status}`)}
                                    </Dropdown.Item>
                                ))}
                            </Dropdown.Menu>
                        </Dropdown>
                        <PrintButton
                            module={module}
                            componentToPrintRef={componentToPrintRef}
                        />
                    </div>
                </div>
                {areTasksAvailableAfterFiltering.finishedFiltering && (
                    <div className="best-contributions pb-5" id="best-contributions-top">
                        {!areTasksAvailableAfterFiltering.value && (
                            <p className="error-placeholder print-hide h4 py-3">{t('action_plan.no_task_match_filters')}</p>
                        )}
                        {filteredBestContributions?.map(bestContribution => (
                            <BestContribution
                                key={bestContribution._id}
                                bestContribution={bestContribution}
                                setFilteredBestContributions={setFilteredBestContributions}
                                deleteBestContribution={areYouSureYouWantToDeleteBestContribution}
                            />
                        ))}
                        <PermissionWrapper allowed={[ROLES_PERMISSIONS.BEST_CONTRIBUTION_WRITE]}>
                            <h4
                                className="cursor new-best-contribution print-hide"
                                onClick={() => dispatch(editBestContributionToggle({ isOpened: true, bestContribution: {} }))}
                            >
                                <i className="bi bi-plus-circle me-2"/>
                                {t('best_contribution.add_new_contribution')}
                            </h4>
                        </PermissionWrapper>
                    </div>
                )}
                <PermissionWrapper allowed={[ROLES_PERMISSIONS.TASK_WRITE]}>
                    {showEditTask && !createAction && <EditTask save={save} moduleId={module._id}/>}
                </PermissionWrapper>
                <PermissionWrapper allowed={[ROLES_PERMISSIONS.BEST_CONTRIBUTION_WRITE]}>
                    {showBestContributionForm && <EditBestContribution moduleId={module._id}/>}
                </PermissionWrapper>
                {showAreYouSureYouWantToDeleteBestContributionModal && (
                    <CustomModal
                        title={`${t('best_contribution.delete_best_contribution')}: ${bestContributionToDelete?.title}`}
                        text1={t('best_contribution.are_you_sure_you_want_to_delete_best_contribution')}
                        warningText={t('best_contribution.best_contribution_will_be_temporarily_deleted')}
                        secondaryButtonLabel={t('best_contribution.delete_area_of_improvement.cancel')}
                        primaryButtonLabel={t('best_contribution.delete_area_of_improvement.delete_anyway')}
                        onPrimaryButtonClick={deleteBestContribution}
                        onSecondaryButtonClick={toggleShowAreYouSureYouWantToDeleteBestContributionModal}
                    />
                )}
            </div>
        </PermissionWrapper>
    );
}

export default ActionPlan;
