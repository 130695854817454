/* eslint-disable max-len */
import {
    faFileWord,
    faFileExcel,
    faFilePowerpoint,
    faLink,
    faFilePdf
} from '@fortawesome/free-solid-svg-icons';

export const API_URL = process.env.NODE_ENV === 'development' ?
    `http://${window.location.hostname}:3020/api` : process.env.REACT_APP_API_URL;

export const LANGUAGES = {
    ENGLISH: 'en',
    SWEDISH: 'se'
};

export const DEFAULT_LANGUAGE = LANGUAGES.SWEDISH;

export const MODULE_TYPES = {
    IDEAS: 1,
    ACTIONS: 2,
    TEXT: 3,
    ATTENDANCE: 4,
    GOALS_AND_METRICS: 5,
    DOCUMENTS: 6
};

export const MAX_NUMBER_OF_MODULE_TYPES = {
    3: 2,
    5: 1
};

export const AGENDA_MODULE_TYPES = {
    1: 'ideas',
    2: 'actions',
    3: 'text',
    4: 'attendance',
    5: 'goals_and_metrics',
    6: 'documents'
};

export const ROLES = {
    user: { key: 'user', name: 'user', level: 0 },
    teamAdmin: { key: 'team_admin', name: 'teamAdmin', level: 1 },
    companyAdmin: { key: 'company_admin', name: 'companyAdmin', level: 2 },
    superAdmin: { key: 'super_admin', name: 'superAdmin', level: 3 }
};

export const REGEX = {
    EMAIL: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    PHONE: /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
    PASSWORD: /^(?=.*[0-9])(?=.*[!@#\][:()"`;+\-'|_?,.</\\>=$%}{^&*~])[a-zA-Z0-9!@#\][:()"`;+\-'|_?,.</\\>=$%}{^&*~]{6,16}$/,
    URL: /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g
};

export const EXTENSIONS_ICONS = {
    doc: faFileWord,
    docx: faFileWord,
    pdf: faFilePdf,
    ppt: faFilePowerpoint,
    pptx: faFilePowerpoint,
    xls: faFileExcel,
    xlsx: faFileExcel,
    csv: faFileExcel,
    other: faLink
};

export const FALLBACK_EXTENSION_LINKS = 'other';

export const TOAST_TYPE = {
    ERROR: 'danger',
    SUCCESS: 'success'
};

export const AREA_OF_IMPROVEMENT_STATUSES = [
    'not-started',
    'in-progress',
    'delayed',
    'done'
];

export const SORT = { ASC: 'asc', DESC: 'desc' };

export const ROLES_PERMISSIONS = {
    INVITE_READ: 'Invite.read',
    INVITE_WRITE: 'Invite.write',
    USER_READ: 'User.read',
    USER_WRITE: 'User.write',
    COMPANY_READ: 'Company.read',
    COMPANY_WRITE: 'Company.write',
    TEAM_READ: 'Team.read',
    TEAM_WRITE: 'Team.write',
    AGENDA_READ: 'Agenda.read',
    AGENDA_WRITE: 'Agenda.write',
    TASK_READ: 'Task.read',
    TASK_WRITE: 'Task.write',
    USER_SETTINGS_READ: 'UserSettings.read',
    USER_SETTINGS_WRITE: 'UserSettings.write',
    ATTENDANCE_READ: 'Attendance.read',
    ATTENDANCE_WRITE: 'Attendance.write',
    STARTING_POINT_READ: 'StartingPoint.read',
    STARTING_POINT_WRITE: 'StartingPoint.write',
    GOALS_AND_METRICS_READ: 'GoalsAndMetrics.read',
    GOALS_AND_METRICS_WRITE: 'GoalsAndMetrics.write',
    TEXTBOX_READ: 'Textbox.read',
    TEXTBOX_WRITE: 'Textbox.write',
    BEST_CONTRIBUTION_READ: 'BestContribution.read',
    BEST_CONTRIBUTION_WRITE: 'BestContribution.write',
    DOCUMENT_READ: 'Document.read',
    DOCUMENT_WRITE: 'Document.write',
    MEETING_READ: 'Meeting.read',
    MEETING_WRITE: 'Meeting.write'
};

export const COLORS = {
    PRIMARY_COLOR: '#27BDBE',
    IN_PROGRESS: '#f7b353',
    DONE: '#27BDBE',
    DELAYED: 'darkred',
    NOT_STARTED: 'gray'
};

export const TEXTBOX_MODULE_DESCRIPTIONS = {
    'Enter a description of how you feel that the situation is today, where do you start from.': 'textbox_module.our_current_state_description',
    'Enter a colourful description of how you want the situation to be when you have reached your goal for this period.': 'textbox_module.our_desired_state_description',
    'Please document your learnings per meeting in chronological order.': 'textbox_module.learning_description'
};
