import { useReactToPrint } from 'react-to-print';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

function PrintButton({ module, componentToPrintRef }) {
    const { t } = useTranslation();
    const [isPrinting, setIsPrinting] = useState(false);
    const promiseResolveRef = useRef(null);

    useEffect(() => {
        if (isPrinting && promiseResolveRef.current) {
            promiseResolveRef.current();
        }
    }, [isPrinting]);

    const handlePrint = useReactToPrint({
        contentRef: componentToPrintRef,
        documentTitle: module?.title || 'Document Title',
        onBeforePrint: () => new Promise((resolve) => {
            promiseResolveRef.current = resolve;
            setIsPrinting(true);
        }),
        onAfterPrint: () => {
            promiseResolveRef.current = null;
            setIsPrinting(false);
        },
        onPrintError: (errorLocation, error) => {
            console.error(`Error in ${errorLocation}:`, error);
        },
        pageStyle: `
          @media print {
            body {
              -webkit-print-color-adjust: exact;
              print-color-adjust: exact;
            }
          }
        `
    });

    return (
        <div
            className="filter-button print-button cursor me-2"
            onClick={() => handlePrint()}
        >
            <i className="bi bi-printer me-2 action-plan-icon"/>
            {t('action_plan.print')}
        </div>
    );
}

export default PrintButton;
