import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

import { API_URL, REGEX } from '../constants';
import { showToastErrorMessage } from '../features/toastSlice';
import ErrorPage from './errorPage';

const SignUp = function () {
    const [invitationData, setInvitationData] = useState();
    const [validated, setValidated] = useState(false);
    const [validationErrors, setValidationErrors] = useState({});
    const [errorPage, setErrorPage] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { id } = useParams();

    useEffect(() => {
        (async () => {
            try {
                if (localStorage.getItem('user')) {
                    localStorage.removeItem('user');
                    localStorage.removeItem('x-access-token');
                }
                const invitation = await axios.get(`${API_URL}/invitation/${id}`);
                setInvitationData(invitation.data);

                if (invitation?.data?.alreadyMember) {
                    navigate('/sign-in');
                }
            } catch (error) {
                setErrorPage(true);
            }
        })();
    }, [id, navigate]);

    const handleField = (field, value) => {
        const trimmedValue = value.trim();
        const nameRegex = /^(?=.{1,50}$)[\p{L}_]+(?: [\p{L}_]+)*$/u;

        if (!trimmedValue) {
            setValidationErrors({
                ...validationErrors,
                [field]: true
            });
            return;
        }

        if (['firstName', 'lastName'].includes(field)) {
            setValidationErrors({
                ...validationErrors,
                [field]: !nameRegex.test(trimmedValue)
            });
        }

        if (field === 'password') {
            setValidationErrors({
                ...validationErrors,
                [field]: !REGEX.PASSWORD.test(value)
            });
        }

        if (field === 'confirmPassword') {
            setValidationErrors({
                ...validationErrors,
                [field]: (!REGEX.PASSWORD.test(value) || invitationData?.user?.password !== value)
            });
        }

        setInvitationData({
            ...invitationData,
            user: {
                ...invitationData?.user,
                [field]: value
            }
        });
    };

    const formIsValid = () => {
        if (
            invitationData?.user?.firstName &&
            invitationData?.user?.lastName &&
            invitationData?.user?.email &&
            (
                (
                    !invitationData?.user?.isActive &&
                    invitationData?.user?.password &&
                    invitationData?.user?.confirmPassword
                ) ||
                invitationData?.user?.isActive
            ) &&
            Object.values(validationErrors).every(value => value === false)
        ) {
            return true;
        }
        return false;
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        setValidated(true);

        if (formIsValid()) {
            try {
                const data = {
                    invitationId: invitationData?._id,
                    user: {
                        ...invitationData?.user,
                        teamId: invitationData?.team?._id
                    }
                };
                const userId = invitationData?.user?._id;
                const updatedUser = await axios.put(`${API_URL}/user/${userId}/signup`, data);

                if (updatedUser.data) {
                    localStorage.setItem('user', JSON.stringify(updatedUser?.data?.user));
                    localStorage.setItem('x-access-token', updatedUser?.data?.token);
                    navigate('/');
                }
            } catch (error) {
                dispatch(showToastErrorMessage(t('error_message.sign_up.failed_sign_up')));
            }
        }
    };

    if (errorPage) {
        return (
            <ErrorPage
                message={t('error_message.sign_up.invitation_does_not_exist')}
                code="404"
                showLinkToLoginPage
            />
        );
    }

    return (
        invitationData ?
            <div className="container sign-up-container h-100 d-flex flex-row align-items-center justify-content-center">
                <Form
                    className="col-md-5 sign-up form-wrapper"
                    noValidate
                    validated={validated}
                    onSubmit={handleSubmit}
                >
                    {invitationData?.company?.logo &&
                        <div className="d-flex flex-column align-items-center">
                            <img src={invitationData?.company?.logo} alt="company-logo" className="sign-up-logo"/>
                        </div>
                    }
                    <Form.Group className="mb-4" controlId="firstName">
                        <Form.Label className="mb-0">{t('sign_up.first_name')}</Form.Label>
                        <Form.Control
                            required
                            isInvalid={validationErrors.firstName}
                            className="form-control mb-3"
                            placeholder={t('sign_up.first_name')}
                            name="firstName"
                            defaultValue={invitationData?.user?.firstName}
                            onChange={(event) => handleField('firstName', event.target.value)}
                        />
                        <Form.Control.Feedback type="invalid" style={{ marginTop: '-18px', height: '1px' }}>
                            {t('error_message.missing_correct_input')}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-4" controlId="lastName">
                        <Form.Label className="mb-0">{t('sign_up.last_name')}</Form.Label>
                        <Form.Control
                            required
                            isInvalid={validationErrors.lastName}
                            className="form-control mb-3"
                            placeholder={t('sign_up.last_name')}
                            name="lastName"
                            defaultValue={invitationData?.user?.lastName}
                            onChange={(event) => handleField('lastName', event.target.value)}
                        />
                        <Form.Control.Feedback type="invalid" style={{ marginTop: '-18px', height: '1px' }}>
                            {t('error_message.missing_correct_input')}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-4" controlId="phone">
                        <Form.Label className="mb-0">{t('sign_up.phone')}</Form.Label>
                        <Form.Control
                            className="form-control mb-3"
                            placeholder={t('sign_up.phone')}
                            name="phone"
                            type="number"
                            defaultValue={invitationData?.user?.phone}
                            onChange={(event) => handleField('phone', event.target.value)}
                        />
                    </Form.Group>
                    <Form.Group className="mb-4" controlId="email">
                        <Form.Label className="mb-0">{t('sign_up.email')}</Form.Label>
                        <Form.Control
                            required
                            isInvalid={validationErrors.email}
                            className="form-control mb-3"
                            placeholder={t('sign_up.email')}
                            name="email"
                            type="email"
                            defaultValue={invitationData?.user?.email}
                            onChange={(event) => handleField('email', event.target.value)}
                            disabled
                        />
                    </Form.Group>
                    {!invitationData.user.isActive &&
                        <div>
                            <div className="password-group">
                                <Form.Group className="mb-4 w-100 mt-1" controlId="password">
                                    <Form.Label className="mb-0">{t('sign_up.password')}</Form.Label>
                                    <Form.Control
                                        required
                                        isInvalid={validationErrors.password}
                                        className="form-control mb-3"
                                        placeholder={t('sign_up.password')}
                                        name="password"
                                        type="password"
                                        defaultValue={invitationData?.user?.password}
                                        onChange={(event) => handleField('password', event.target.value)}
                                    />
                                    <Form.Control.Feedback type="invalid" style={{ marginTop: '-18px', height: '1px' }}>
                                        {t('error_message.missing_correct_input')}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group className="mb-4 w-100 mt-1 ms-1" controlId="confirmPassword">
                                    <Form.Label className="mb-0">{t('sign_up.confirm_password')}</Form.Label>
                                    <Form.Control
                                        required
                                        isInvalid={validationErrors.confirmPassword}
                                        className="form-control mb-3"
                                        placeholder={t('sign_up.confirm_password')}
                                        name="confirmPassword"
                                        type="password"
                                        defaultValue={invitationData?.user?.confirmPassword}
                                        onChange={(event) => handleField('confirmPassword', event.target.value)}
                                    />
                                    <Form.Control.Feedback type="invalid" style={{ marginTop: '-18px', height: '1px' }}>
                                        {t('error_message.missing_correct_input')}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </div>
                            <span className="password-message">
                                {t('error_message.password_format_message')}
                            </span>
                        </div>
                    }
                    <Form.Group className="mb-4 mt-4" controlId="company">
                        <Form.Label className="mb-0">{t('sign_up.company')}</Form.Label>
                        <Form.Control
                            className="form-control mb-3"
                            placeholder={t('sign_up.company')}
                            name="company"
                            defaultValue={invitationData.company?.name}
                            disabled
                        />
                    </Form.Group>
                    <Form.Group className="mb-4" controlId="team">
                        <Form.Label className="mb-0">{t('sign_up.team')}</Form.Label>
                        <Form.Control
                            className="form-control mb-3"
                            placeholder={t('sign_up.team')}
                            name="team"
                            defaultValue={invitationData?.team?.name}
                            disabled
                        />
                    </Form.Group>
                    <div className="d-flex justify-content-center">
                        <button type="submit" className="primary-button w-75 sign-button">
                            {invitationData?.user?.isActive ? t('sign_up.confirm') : t('sign_up.sign_up')}
                        </button>
                    </div>
                </Form>
            </div> :
            <div className="d-flex justify-content-center m-5 loader-wrapper">
                <div className="spinner-grow text-info loader" role="status"/>
                <span className="loader-text">{t('dashboard_shared.loading')}</span>
            </div>
    );
};

export default SignUp;
