import { useTranslation } from 'react-i18next';
import { logout } from '../features/agendaSlice';
import { store } from '../store';
import { ROLES } from '../constants';

function PermissionWrapper({ allowed, role, children, displayNoAccessMessage, fallbackComponent }) {
    const { t } = useTranslation();
    const userString = localStorage.getItem('user');
    if (userString === 'undefined') {
        store.dispatch(logout());
    }

    if (!allowed?.length) {
        return children;
    }

    const user = userString ? JSON.parse(userString) : {};
    const userRole = role && [ROLES.user?.name, ROLES.teamAdmin?.name].includes(user?.role?.name) ? role : user?.role;

    const filteredPermissions = userRole?.permissions?.filter(value => allowed?.includes(value));

    if (filteredPermissions?.length) {
        return children;
    }

    if (displayNoAccessMessage) {
        return <h4 className="m-5">{t('error_message.you_have_no_access')}</h4>;
    }

    return fallbackComponent || null;
}

export default PermissionWrapper;
