import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import _ from 'underscore';

import { axios } from '../helpers/apiHelper';
import i18n from '../i18n';
import { showToastErrorMessage } from './toastSlice';

const initialState = {
    userData: {},
    sections: [],
    showAgendaModal: false,
    editAgendaInfo: false,
    editSectionTitle: {},
    editModule: {},
    newSection: {},
    newModule: {},
    agendaOpenedOnMobileScreens: false
};

const reorderModules = async (data) => {
    const { sectionIndex, moduleIndex, modules } = data;

    const reorderedModules = await axios.put('/module/reorder', { modules });

    return {
        sectionIndex,
        moduleIndex,
        modules: reorderedModules.data
    };
};

const reorderSections = async (data) => {
    const { sectionIndex, sections } = data;

    const reorderedSections = await axios.put('/section/reorder', { sections });

    return {
        sectionIndex,
        sections: reorderedSections.data
    };
};

export const fetchUser = createAsyncThunk('fetchUser', async (arg, { dispatch, rejectWithValue }) => {
    try {
        const loggedUser = JSON.parse(localStorage.user);

        const user = await axios.get(`/user/${loggedUser?._id}`);

        return user.data;
    } catch (err) {
        dispatch(showToastErrorMessage(i18n.t('error_message.global_message')));
        return rejectWithValue(err.message);
    }
});

export const fetchSections = createAsyncThunk('fetchSections', async (arg, { dispatch, rejectWithValue }) => {
    try {
        const loggedUser = JSON.parse(localStorage.user);

        const sections = await axios.get(`/section/?teamId=${loggedUser?.lastSelectedTeam?._id}`);

        return sections?.data;
    } catch (err) {
        dispatch(showToastErrorMessage(i18n.t('error_message.global_message')));
        return rejectWithValue(err.message);
    }
});

export const saveAgendaInfo = createAsyncThunk('updateAgendaInfo', async (agendaData, { dispatch, rejectWithValue }) => {
    try {
        const loggedUser = JSON.parse(localStorage.user);

        const agendaInfo = await axios.put(
            `/team/${loggedUser?.lastSelectedTeam?._id}/updateAgenda`,
            { agendaData }
        );

        return agendaInfo.data;
    } catch (err) {
        dispatch(showToastErrorMessage(i18n.t('error_message.global_message')));
        return rejectWithValue(err.message);
    }
});

export const saveSection = createAsyncThunk('updateSectionTitle', async (data, { dispatch, rejectWithValue }) => {
    try {
        const { sectionIndex } = data;
        const { _id: sectionId, title } = data.section;

        const section = await axios.put(`/section/${sectionId}`, { title });

        return {
            sectionIndex,
            title: section.data.title
        };
    } catch (err) {
        dispatch(showToastErrorMessage(i18n.t('error_message.global_message')));
        return rejectWithValue(err.message);
    }
});

export const deleteSection = createAsyncThunk('deleteSection', async ({ _id }, { dispatch, rejectWithValue }) => {
    try {
        await axios.delete(`/section/${_id}`);
    } catch (err) {
        dispatch(showToastErrorMessage(i18n.t('error_message.global_message')));
        return rejectWithValue(err.message);
    }
});

export const saveModule = createAsyncThunk('updateModule', async (data, { dispatch, rejectWithValue }) => {
    try {
        const { sectionIndex, moduleIndex, module } = data;

        const moduleData = await axios.put(`/module/${module._id}`, { module });

        return {
            sectionIndex,
            moduleIndex,
            module: moduleData?.data
        };
    } catch (err) {
        dispatch(showToastErrorMessage(i18n.t('error_message.global_message')));
        return rejectWithValue(err.message);
    }
});

export const deleteModule = createAsyncThunk('deleteModule', async ({ _id }, { dispatch, rejectWithValue }) => {
    try {
        await axios.delete(`/module/${_id}`);
    } catch (err) {
        dispatch(showToastErrorMessage(i18n.t('error_message.global_message')));
        return rejectWithValue(err.message);
    }
});

export const moveUpSection = createAsyncThunk('moveUpSection', async (data, { dispatch, rejectWithValue }) => {
    try {
        return (await reorderSections(data));
    } catch (err) {
        dispatch(showToastErrorMessage(i18n.t('error_message.global_message')));
        return rejectWithValue(err.message);
    }
});

export const moveDownSection = createAsyncThunk('moveDownSection', async (data, { dispatch, rejectWithValue }) => {
    try {
        return (await reorderSections(data));
    } catch (err) {
        dispatch(showToastErrorMessage(i18n.t('error_message.global_message')));
        return rejectWithValue(err.message);
    }
});

export const moveUpModule = createAsyncThunk('moveModuleUp', async (data, { dispatch, rejectWithValue }) => {
    try {
        return (await reorderModules(data));
    } catch (err) {
        dispatch(showToastErrorMessage(i18n.t('error_message.global_message')));
        return rejectWithValue(err.message);
    }
});

export const moveDownModule = createAsyncThunk('moveModuleDown', async (data, { dispatch, rejectWithValue }) => {
    try {
        return (await reorderModules(data));
    } catch (err) {
        dispatch(showToastErrorMessage(i18n.t('error_message.global_message')));
        return rejectWithValue(err.message);
    }
});

export const saveNewModule = createAsyncThunk('saveNewModule', async (data, { dispatch, rejectWithValue }) => {
    try {
        const { sectionIndex, module } = data;

        const newModule = await axios.post('/module/', { module });

        return {
            sectionIndex,
            newModule: newModule.data
        };
    } catch (err) {
        dispatch(showToastErrorMessage(i18n.t('error_message.global_message')));
        return rejectWithValue(err.message);
    }
});

export const saveNewSection = createAsyncThunk('saveNewSection', async (section, { dispatch, rejectWithValue }) => {
    try {
        const newSection = await axios.post('/section/', { section });

        return newSection.data;
    } catch (err) {
        dispatch(showToastErrorMessage(i18n.t('error_message.global_message')));
        return rejectWithValue(err.message);
    }
});

export const handleSwitchingLastSelectedTeam = createAsyncThunk('handleSwitchingTeam', async (args, { dispatch, rejectWithValue }) => {
    try {
        // fetch user with new lastSelectedTeam
        await dispatch(fetchUser());
        // if user is on module page redirect him to dashboard
        if (window.location.pathname.split('/')[1] === 'module') {
            window.location.replace('/');
        }
    } catch (err) {
        dispatch(showToastErrorMessage(i18n.t('error_message.global_message')));
        return rejectWithValue(err.message);
    }
});

export const agendaSlice = createSlice({
    name: 'agenda',
    initialState,
    reducers: {
        setUser: (state, action) => {
            state.userData = action.payload;
            const user = { ...action.payload };
            delete user.teams;
            localStorage.setItem('user', JSON.stringify(user));
        },

        // header
        logout: () => {
            localStorage.removeItem('x-access-token');
            localStorage.removeItem('user');
            window.location.replace('/sign-in');
        },

        // modal
        agendaModalToggle: (state) => {
            state.showAgendaModal = !state.showAgendaModal;
        },

        closeModal: (state) => {
            state.showAgendaModal = false;
            state.editAgendaInfo = false;
            state.editSectionTitle = {};
            state.editModule = {};
            state.newSection = {};
            state.newModule = {};
        },

        // agenda info
        editAgendaInfoToggle: (state) => {
            state.editAgendaInfo = !state.editAgendaInfo;
        },

        // sections
        editSectionToggle: (state, action) => {
            state.editSectionTitle = {
                [action.payload.sectionIndex]: action.payload.isOpened
            };
        },

        // new section
        addNewSection: (state) => {
            state.newSection = {
                title: '',
                modules: [],
                order: state.sections.length + 1,
                teamId: state.userData.lastSelectedTeam._id
            };
        },

        cancelNewSection: (state) => {
            state.newSection = {};
        },

        // modules
        editModuleToggle: (state, action) => {
            state.editModule = { [action.payload.sectionIndex]: { [action.payload.moduleIndex]: action.payload.isOpened } };
        },

        // new module
        addNewModule: (state, action) => {
            state.newModule = {
                [action.payload]: {
                    title: '',
                    type: 0,
                    order: state.sections[action.payload].modules.length + 1,
                    sectionId: state.sections[action.payload]._id
                }
            };
        },

        cancelNewModule: (state) => {
            state.newModule = {};
        },

        setAgendaOpenedOnMobileScreen: (state, action) => {
            state.agendaOpenedOnMobileScreens = action.payload;
        }
    },
    extraReducers: {
        [fetchUser.fulfilled]: (state, action) => {
            state.userData = action.payload;
            if (action.payload) {
                const user = { ...action.payload };
                delete user.teams;
                localStorage.setItem('user', JSON.stringify(user));
            }
        },
        [fetchSections.fulfilled]: (state, action) => {
            state.sections = action.payload;
        },
        [saveAgendaInfo.fulfilled]: (state, action) => {
            state.userData.lastSelectedTeam.agendaTitle = action.payload.agendaTitle;
            state.userData.lastSelectedTeam.agendaDescription = action.payload.agendaDescription;
        },
        [saveSection.fulfilled]: (state, action) => {
            state.sections[action.payload.sectionIndex].title = action.payload.title;
        },
        [saveModule.fulfilled]: (state, action) => {
            state.sections[action.payload.sectionIndex].modules[action.payload.moduleIndex] = action.payload.module;
        },
        [moveUpModule.fulfilled]: (state, action) => {
            const [FirstModule, SecondModule] = action.payload.modules;
            state.sections[action.payload.sectionIndex].modules[action.payload.moduleIndex - 1] = FirstModule;
            state.sections[action.payload.sectionIndex].modules[action.payload.moduleIndex] = SecondModule;
        },
        [moveDownModule.fulfilled]: (state, action) => {
            const [FirstModule, SecondModule] = action.payload.modules;
            state.sections[action.payload.sectionIndex].modules[action.payload.moduleIndex] = FirstModule;
            state.sections[action.payload.sectionIndex].modules[action.payload.moduleIndex + 1] = SecondModule;
        },
        [moveUpSection.fulfilled]: (state, action) => {
            state.sections[action.payload.sectionIndex].order -= 1;
            state.sections[action.payload.sectionIndex - 1].order += 1;
            state.sections = _.sortBy(state.sections, 'order');
        },
        [moveDownSection.fulfilled]: (state, action) => {
            state.sections[action.payload.sectionIndex].order += 1;
            state.sections[action.payload.sectionIndex + 1].order -= 1;
            state.sections = _.sortBy(state.sections, 'order');
        },
        [saveNewModule.fulfilled]: (state, action) => {
            state.sections[action.payload.sectionIndex].modules.push(action.payload.newModule);
        },
        [saveNewSection.fulfilled]: (state, action) => {
            state.sections.push(action.payload);
        }
    }
});

export const {
    setUser,
    logout,
    agendaModalToggle,
    editAgendaInfoToggle,
    editSectionToggle,
    addNewSection,
    cancelNewSection,
    editModuleToggle,
    addNewModule,
    cancelNewModule,
    closeModal,
    setAgendaOpenedOnMobileScreen
} = agendaSlice.actions;

export default agendaSlice.reducer;
